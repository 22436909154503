import React, { Suspense } from "react";

import { Switch, Route } from "react-router-dom";

// * Main bundle - Marketing site.
import Landing from "./Components/Public/Landing/Landing";
import Promise from "./Components/Public/Promise/Promise";
import EscrowServices from "./Components/Public/EscrowServices/EscrowServices";
import CompanyProfile from "./Components/Public/Companies/CompanyProfile/CompanyProfile";
// import Admin from "./Components/Admin/Admin";
// import CompanyPortal from "./Components/Company/CompanyPortal/CompanyPortal";
// import CustomerPortal from "./Components/Customer/CustomerPortal/CustomerPortal";
import Pricing from "./Components/Public/Pricing/Pricing";
import PasswordReset from "./Components/Public/Login/PasswordReset";
import ForgotPassword from "./Components/Public/Login/ForgotPassword";
import Login from "./Components/Public/Login/Login";
import JoinTheFamily from "./Components/Public/Join/Join";
import Contact from "./Components/Public/Contact/Contact";
// const Companies = React.lazy(() =>
//   import("./Components/Public/Companies/Companies")
// );
import Companies from "./Components/Public/Companies/Companies";
import NewJobPage from "./Components/Job/Forms/Company/NewJob/NewJobPage";
// * Lazy loading to reduce bundle size significantly.
const AdminViewJob = React.lazy(() =>
  import("./Components/Job/ViewJob/AdminViewJob")
);
const NewUser = React.lazy(() => import("./Components/Public/NewUser/NewUser"));
// const Contact = React.lazy(() => import("./Components/Public/Contact/Contact"));
const OldCustomerJobACH = React.lazy(() =>
  import("./Components/Customer/CustomerPortal/CustomerJobACH")
);
const CustomerJobACH = React.lazy(() =>
  import("./Components/Job/ViewJob/Customer/CustomerJobACH")
);
const Apply = React.lazy(() => import("./Components/Public/Join/Apply/Apply"));
const DirectDepositAuth = React.lazy(() =>
  import("./Components/Company/CompanyPortal/DirectDepositAuth")
);
const Portal = React.lazy(() => import("./Components/Portal/Portal"));
const EmailLogin = React.lazy(() => import("./Components/Email/EmailLogin"));
const JobAgreement = React.lazy(() =>
  import("./Components/Job/Forms/JobAgreement/JobAgreement")
);
const UnsignedContracts = React.lazy(() =>
  import("./Components/Admin/JobManager/UnsignedContracts")
);
const Contracts = React.lazy(() =>
  import("./Components/Admin/JobManager/Contracts")
);
const PayRelease = React.lazy(() =>
  import("./Components/Job/Forms/PayRelease/PayRelease")
);
const SignedPage = React.lazy(() =>
  import("./Components/Contracts/SignedPage")
);
const Dashboard = React.lazy(() =>
  import("./Components/Admin/Dashboard/Dashboard")
);
const CustomerManager = React.lazy(() =>
  import("./Components/Admin/CustomerManager/CustomerManager")
);
const CompanyManager = React.lazy(() =>
  import("./Components/Admin/CompanyManager/CompanyManager")
);
const JobManager = React.lazy(() =>
  import("./Components/Admin/JobManager/JobManager")
);
const ReportManager = React.lazy(() =>
import("./Components/Admin/ReportManager/ReportManager")
);
const PaymentManager = React.lazy(() =>
  import("./Components/Admin/Payments/PaymentManager")
);
const ManageJob = React.lazy(() =>
  import("./Components/Admin/JobManager/ManageJob")
);

export default (
  <Switch>
    <Suspense fallback={<div></div>}>
      <Route exact path="/" component={Landing} />
      <Route exact path="/promise" component={Promise} />
      <Route exact path="/pricing" component={Pricing} />
      <Route exact path="/portal" component={Portal} />
      <Route exact path="/join" component={JoinTheFamily} />

      <Route exact path="/companies/service/:service?" component={Companies} />
      <Route path="/companies/:state?/:service?" component={Companies} />

      <Route exact path="/escrow" component={EscrowServices} />
      <Route exact path="/email" component={EmailLogin} />
      {/* <Route exact path="/customer/portal" component={CustomerPortal} /> */}
      <Route exact path="/portal/ach/:job_id" component={OldCustomerJobACH} />
      <Route exact path="/portal/job/:job_id/ach" component={CustomerJobACH} />
      
      <Route
        exact
        path="/portal/direct-deposit"
        component={DirectDepositAuth}
      />
        <Route
        exact
        path="/portal/new-project/:type"
        component={NewJobPage}

      />
      <Route
        exact
        path="/portal/signed/:contract_name"
        component={SignedPage}
      />
      <Route exact path="/admin/signed/:contract_name" component={SignedPage} />
      <Route
        exact
        path="/portal/job/:job_id/payment-release"
        component={PayRelease}
      />

      <Route
        exact
        path="/portal/project-agreement/:signer_type/:job_id"
        component={JobAgreement}
      />
      {/* <Route exact path="/company/portal" component={CompanyPortal} /> */}
      <Route exact path="/password/reset/:code" component={PasswordReset} />
      <Route exact path="/password/forgot" component={ForgotPassword} />
      <Route exact path="/company/:id" component={CompanyProfile} />
      <Route exact path="/apply" component={Apply} />
      <Route exact path="/admin" component={Dashboard} />
      <Route exact path="/admin/job/:job_id" component={ManageJob} />
      <Route
        exact
        path="/admin/job/:job_id/unsigned-contracts"
        component={UnsignedContracts}
      />
      <Route exact path="/admin/dashboard" component={Dashboard} />
      <Route exact path="/admin/users" component={CustomerManager} />
      <Route exact path="/admin/contractors" component={CompanyManager} />
      <Route exact path="/admin/projects" component={JobManager} />
      <Route exact path="/admin/payments" component={PaymentManager} />
      <Route exact path="/admin/reports" component={ReportManager} />

      <Route exact path="/admin/job/:job_id/contracts" component={Contracts} />
      <Route exact path="/contact" component={Contact} />
      <Route exadminact path="/new" component={NewUser} />
      <Route path="/customer/new/:hash" component={NewUser} />
      <Route exact path="/login" component={Login} />
    </Suspense>
  </Switch>
);
